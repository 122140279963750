@use '../../../../styles/variables.sass'

.link
    color: variables.$dark
    background-color: variables.$opacity
    border: 1px solid variables.$opacity
    display: flex
    text-decoration: none
    transition: 0.4s
    &:hover
        color: variables.$dark
        background-color: variables.$cultured
        border: 1px solid variables.$opacity
        text-decoration: none
    &:active
        color: variables.$white !important
        background-color: variables.$primary !important
        border: 1px solid variables.$opacity !important
        text-decoration: none
    &:focus
        color: variables.$white !important
        background-color: variables.$primary !important
        border: 1px solid variables.$opacity !important
        text-decoration: none

.btn_link
    color: variables.$dark
    background-color: variables.$opacity
    border: 1px solid variables.$opacity
    display: flex
    text-decoration: none
    &:hover
        color: variables.$dark
        background-color: variables.$opacity
        border: 1px solid variables.$opacity
        text-decoration: none
    &:active
        color: variables.$white !important
        background-color: variables.$opacity !important
        border: 1px solid variables.$opacity !important
        text-decoration: none
    &:focus
        color: variables.$white !important
        background-color: variables.$opacity !important
        border: 1px solid variables.$opacity !important
        text-decoration: none