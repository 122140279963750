@use '../../../Styles/variables'

.tableFilter
    display: flex
    flex-direction: row
    justify-content: space-between

.Results
    align-self: flex-end

.Filter
    margin-bottom: 5px

.btnAdd
    margin-right: 12px