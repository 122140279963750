@use './variables.sass'

.rmsc
    .dropdown-container 
        border: var(--bs-border-width) solid var(--bs-border-color) !important
        border-radius: .375rem !important
        box-shadow: 0px 0px 0px 0px rgba(22, 160, 133, 0.5) !important
        &:focus
            border-color: variables.$primary !important
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important
        &:focus-within
            border-color: variables.$primary !important
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important
        &:active
            border-color: variables.$primary !important
            box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5) !important
    .gray
        color: variables.$black !important

.dropdown-heading > .rmsc
    border-color: transparent

.dropdown-heading-dropdown-arrow
    display: none

.dropdown-heading
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e)
    display: block
    width: 100%
    padding: .375rem 2.25rem .375rem .75rem
    -moz-padding-start: calc(0.75rem - 3px)
    font-size: 1rem
    font-weight: 400
    line-height: 1.5
    color: var(--bs-body-color)
    background-color: var(--bs-form-control-bg)
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none)
    background-repeat: no-repeat
    background-position: right .75rem center
    background-size: 16px 12px
    border-radius: .375rem
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

.DropdownItem
    max-width: 100ch
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    &:active
        background-color: variables.$primary

.DropdownItem:not(.first)
    margin-top: 8px

.dropdown-toggle::after
    content: none

.dropdown-toggle.btn.btn-primary::after
    content: none

.dropdown-toggle
    display: inline
    color: variables.$primary
    background-color: variables.$white
    border-style: none
    box-shadow: 0 0 0 0 variables.$primary !important
    &:hover
        color: variables.$primary !important
        background-color: variables.$cultured !important
        box-shadow: 0 0 0 0 variables.$primary !important
    &:active
        color: variables.$primary !important
        background-color: variables.$cultured !important
        box-shadow: 0 0 0 0 variables.$primary !important
    &:focus
        color: variables.$primary !important
        background-color: variables.$cultured !important
        box-shadow: 0 0 0 0 variables.$primary !important
    &:not(:active)
        color: variables.$primary
        background-color: variables.$white

@media only screen and (max-width: variables.$xl)
    .ant-table-cell
        min-width: 300px
    .ant-table-row-expand-icon-cell
        min-width: 1px
        