@use '../../../../styles/variables.sass'

.btn_link
    color: variables.$primary
    background-color: variables.$opacity
    border: 1px solid variables.$opacity
    display: flex
    transition-duration: 0.4s
    text-decoration: none
    &:hover
        color: variables.$primary
        background-color: variables.$opacity
        border: 1px solid variables.$opacity
        text-decoration: underline
        
    &:active
        color: variables.$primary !important
        background-color: variables.$opacity !important
        border: 1px solid variables.$opacity !important