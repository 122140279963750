@use './styles/variables'

.container
    padding: 0
    margin: 0
    width: 100% 
    max-width:1980px

body
    background-color: variables.$background 
    padding: 0
    margin: 0 
     

html
    background-color: variables.$background
    


.contentGeneral
    display: flex
    width: 100%
    flex-direction: row
    padding-top: 65px
    height: 100%
    .ContentSideBarMenu
        width: 18%  
        max-width: 300px
    .contentDashboard
        width: 82%
        padding: 24px 12px
        .contentComponentBackground
            width: 100%
            height: 100%
            border: 1px solid #F3F2F1
            box-shadow: 0px 2px 4px rgba(32, 31, 31, 0.075)
            border-radius: 4px
            background-color: variables.$white
            display: flex
            align-items: center
            flex-direction: column
            padding: 48px 16px 
            

            .ilustration
                width: 50%
                margin-top: 32px

            .p2
                text-align: center



@media only screen and (max-width: variables.$xl)
    .contentGeneral
        display: flex
        width: 100%
        flex-direction: row
        padding-top: 65px
        height: 100%
        .ContentSideBarMenu
            width: 0%  
        .contentDashboard
            width: 100%
            padding: 24px 12px
            .contentComponentBackground
                width: 100%
                height: 100%
                border: 1px solid #F3F2F1
                box-shadow: 0px 2px 4px rgba(32, 31, 31, 0.075)
                border-radius: 4px
                background-color: variables.$white
                display: flex
                align-items: center
                flex-direction: column
                padding: 48px 16px 
                .ilustration
                    width: 50%
                    margin-top: 32px
                .p2
                    text-align: center

    