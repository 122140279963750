@use '../../styles/variables.sass'

.checkBoxContainer
    padding-top: 35px

.inputLarge
    width: 100%

.inputMiddle
    width: 100%

.inputSmall
    width: 50%

.containerBtns
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between

.containerBtns2
    display: flex

.contentCompBG
    width: 100%
    height: 100%
    border: 1px solid #F3F2F1
    box-shadow: 0px 2px 4px rgba(32, 31, 31, 0.075)
    border-radius: 4px
    background-color: variables.$white
    padding: 16px 

.buttonCustom
    margin:5px

.required
    color: red
    margin-left: 4px

.titleContainer
    display: flex
    flex-direction: row
    align-content: space-between
    justify-content: space-between

    .btnRefresh
        display: flex
        justify-content: center

    .btnAfegir
        display: flex
        justify-content: flex-end

.icHelp
    color: variables.$black
    margin-left: 6px
    position: relative
    top: -2px
    left: 2px

@media (max-width: variables.$breakpoint-tablet)
    .inputLarge
        width: 100%

    .inputMiddle
        width: 100%

    .inputSmall
        width: 100%

    .titleContainer
        display: flex
        flex-direction: column
        align-items: start
        justify-content: center
        
        .btnAfegir
            margin-top: 16px
    
    


    