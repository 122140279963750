@use '../../styles/variables.sass'

.filterMobile
    display: none

.filterDesktop
    display: block

.inputLarge
    width: 100%

.inputMiddle
    width: 50%

.inputSmall
    width: 20%

.containerBtns
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between

.containerBtns2
    display: flex

.contentFilterBG
    border: 1px solid variables.$pewter
    box-shadow: 0px 4px 14px rgba(32, 31, 31, 0.1)
    border-radius: 4px

    width: 100%
    height: 100%
    background-color: variables.$white
    padding: 16px 

.buttonCustom
    margin:5px

.required
    color: red
    margin-left: 4px

.titleContainer
    display: flex
    flex-direction: row
    align-content: space-between
    justify-content: space-between

.btnRefresh
    display: flex
    justify-content: center

.btnAfegir
    display: flex
    justify-content: flex-end

    
@media (max-width: variables.$breakpoint-tablet)
    .inputLarge
        width: 100%

    .inputMiddle
        width: 100%

    .inputSmall
        width: 100%
        
    .titleContainer
        display: flex
        flex-direction: column
        align-items: start
        justify-content: center
        
    .btnAfegir
        margin-top: 16px
    
    .filterMobile
        display: block

    .filterDesktop
        display: none
    
