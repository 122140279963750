@use '../../../../styles/variables.sass'


.BtnDelete
    color: variables.$red
    background-color: variables.$white
    border-style: none
    &:hover
        color: variables.$red
        background-color: variables.$red-light
    &:active
        color: variables.$red !important
        background-color: variables.$red-light !important
    &:focus
        color: variables.$red !important
        background-color: variables.$red-light !important
    &:disabled
        background-color: variables.$disabled !important
        color: variables.$white !important