@use '../../styles/variables.sass'
    
.form-check-input:checked
    font-family: variables.$open_sans
    background-color: variables.$primary
    border-color: variables.$primary

.form-check-input
    font-family: variables.$open_sans
    border-color: variables.$pewter
    &:focus
        border-color: variables.$primary
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)

.form-check-input:checked[type=checkbox]
    background-color: variables.$primary
    border-color: variables.$primary

.form-check-input:active[type=checkbox]::before
    background-color: variables.$primary
    border-color: variables.$primary
    background-color: variables.$primary 

.form-check-input:focus[type=checkbox]
    border-color: variables.$primary
    box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)
.form-check-input:checked
    background-color: variables.$primary
    border-color: variables.$primary

.form-check-input
    font-family: variables.$open_sans
    border-color: variables.$pewter
    &:focus
        border-color: variables.$primary
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)

.form-select
    font-family: variables.$open_sans
    &:active
        border-color: variables.$primary
    &:focus
        border-color: variables.$primary
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)

.form-control
    font-family: variables.$open_sans
    &:active
        border-color: variables.$primary
    &:focus
        border-color: variables.$primary
        box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)

.form-check-input:checked[type=checkbox]
    background-color: variables.$primary
    border-color: variables.$primary

.form-check-input:active[type=checkbox]::before
    background-color: variables.$primary
    border-color: variables.$primary
    background-color: variables.$primary 

.form-check-input:focus[type=checkbox]
    border-color: variables.$primary
    box-shadow: 0px 0px 0px 4px rgba(22, 160, 133, 0.5)

.tooltip-inner
    max-width: 700px !important