@use './variables.sass'
    
*
    padding: 0
    margin: 0
    box-sizing: border-box
    font-family: variables.$open_sans
    font-size: 16px

html, body
    width: 100%
    color: variables.$dark
    margin: 0px
    padding: 0px
    
#root 
    height: 100%

.margin_top
    height: 70px

.btn
    display: flex