@use '../../../styles/variables'

input[type="file"]
        display: none

.fileContainers
    display: flex
    flex-direction: column

.fileContainer
    display: inline-flex
    
.uploadButton
    padding-right: 0.75rem
    padding-left: 0.75rem
    padding-top: 0.375rem
    padding-bottom: 0.375rem
    color: variables.$primary
    background-color: variables.$white
    border-radius: 6px
    border: 1px solid variables.$primary
    &:hover
        background-color: variables.$primary-light !important
        border-color: variables.$primary !important
        color: variables.$primary !important
    &:active
        background-color: variables.$primary-light !important
        border-color: variables.$primary !important
        color: variables.$primary

    &::selection
        background-color: variables.$primary-light !important
        border-color: variables.$primary !important
        
    &:disabled
        background-color: variables.$cultured !important
        border-color: variables.$cultured !important
        color: variables.$pewter !important