@use '../../../styles/variables.sass'

.userName
    display: inline

.shortUserName
    display: none

.dropdownSignIn
    color: variables.$black
    
.profileContainer
    display: flex
    white-space: break-spaces
    margin-right: 20px

.navbar 
    z-index: 200
    display: flex
    justify-content: space-between
    background-color: variables.$white
    padding: 1rem
    align-items: center
    position: fixed
    width: 100%
    box-shadow: 0px 2px 4px rgba(32, 31, 31, 0.075)

    ul 
        margin: 0

        li:last-child
            margin-right: 0

    a
        text-decoration: none
        padding: 0.5rem 1rem
        color: variables.$coral
        border-radius: variables.$border_radius_sm
        font-size: 0.9rem
        font-weight: 500

    a:hover
        background-color: variables.$primary_light

.list 
    display: flex
    list-style: none
    align-items: center

.itemBtn 
    display: flex
    margin-left: 24px
    .item 
        margin-right: 1rem

.itemBtnMenu
    display: none


@media (max-width: variables.$breakpoint-tablet)
    .item 
        display: none

    .itemBtnMenu
        display: block

    .userName
        display: none
    .shortUserName
        display: inline




