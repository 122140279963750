@use '../../Styles/variables'

.containerSideBarMenu
    z-index: 100
    height: 100%
    position: fixed
    width: inherit
    max-width: 300px
    .navbar__menu
        height: 100%
        box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15)
        transition: all 0.5s ease
        background-color: variables.$white
        padding: 16px 12px 0 0

    .item__elements
            display: flex
            flex-direction: row
            align-content: space-around
            gap: 10px

    .navbar__list 
        list-style-type: none
        


    .navbar__li 
        width: 100%
        display: flex
        flex-direction: row
        padding: 0px 0px 0px 0px
        
        font-size: 16px
        letter-spacing: 0px
        
        font-weight: 400


    .navbar__liBox
        width: 100%
        padding: 12px 8px
        display: flex
        flex-direction: row
        color: variables.$coral
        border: 1px solid variables.$white
        border-radius: 4px
        text-decoration: none

        &:hover
            background-color: #E8ECEF

        a
            color: variables.$coral
            
        
        &:focus
            background-color: variables.$primary

    .navbar__liBoxActive
        text-decoration: none
        width: 100%
        padding: 12px 8px
        display: flex
        flex-direction: row
        color: #FFFFFF
        border: 1px solid variables.$white
        border-radius: 4px
        background-color: variables.$primary

@media only screen and (max-width: variables.$xl)
    #containerSideBarMenu
        height: 100%
        width: 60%
        position: fixed
    
        .navbar-menu 
            height: 100%
            box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15)
            transition: all 0.5s ease
            background-color: variables.$white
            padding: 16px 12px 0 0
            
            
        
