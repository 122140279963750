@use '../../../../styles/variables.sass'


.loading
    margin-left: 10px
        
.btn_secondary
    border: 1px solid variables.$primary
    color: variables.$primary
    display: flex
    transition-duration: 0.4s
    &:hover
        background-color: variables.$primary_light
        color: variables.$primary
        border: 1px solid variables.$primary
    &:active
        background-color: variables.$primary_light !important
        color: variables.$primary !important
        border: 1px solid variables.$primary !important
    &:disabled
        background-color: variables.$disabled !important
        color: variables.$white !important
        border: 1px solid variables.$disabled !important