@use '../../styles/variables.sass'


.titleContainer
    display: flex
    flex-direction: row
    align-content: space-between
    justify-content: space-between

    .btnRefresh
        display: flex
        justify-content: center

    .btnAfegir
        display: flex
        justify-content: flex-end

.link
    text-decoration: none
    &:hover
        color: variables.$dark
        background-color: variables.$cultured
        border: 1px solid variables.$opacity
        text-decoration: none
    &:active
        color: variables.$white !important
        background-color: variables.$primary !important
        border: 1px solid variables.$opacity !important
        text-decoration: none
    &:focus
        color: variables.$white !important
        background-color: variables.$primary !important
        border: 1px solid variables.$opacity !important
        text-decoration: none
    
@media only screen and (max-width: variables.$md)
    .titleContainer
        display: flex
        flex-direction: column
        align-items: start
        justify-content: center
        
        .btnAfegir
            margin-top: 16px

.inputLarge
    width: 100%

.inputMiddle
    width: 50%

.inputSmall
    width: 20%

.containerBtns
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between

.containerBtns2
    display: flex

.contentCompBG
    width: 100%
    height: 100%
    border: 1px solid #F3F2F1
    box-shadow: 0px 2px 4px rgba(32, 31, 31, 0.075)
    border-radius: 4px
    background-color: variables.$white
    padding: 16px 

.buttonCustom
    margin:5px

.required
    color: red
    margin-left: 4px

@media (max-width: variables.$breakpoint-tablet)

       


@media (max-width: variables.$breakpoint-phone)

       
    

    
    


    