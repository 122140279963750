@use './styles/variables.sass'

.container
    padding: 0
    margin: 0
    width: 100% 
    max-width: inherit

body
    background-color: variables.$background 
    padding: 0
    margin: 0 

html
    background-color: variables.$background
    
.btn-close
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem variables.$primary

.table-responsive 
    overflow-x: inherit
    overflow-y: inherit

.app-spinner
    display: flex
    justify-content: center
    vertical-align: middle
    img 
        margin: 0 auto

#hamburguerMenu
    display: none

@media only screen and (max-width: 1280px)
    .table-responsive 
        overflow-x: auto
        overflow-y: auto

    #hamburguerMenu
        display: block

    #contentGeneral
            #ContentSideBarMenu
                width: 20%
        
            #contentDashboard
                width: 100%
