@use '../../../../styles/variables.sass'

.btn_primary
    background-color: #F8F9FA
    color: variables.$black
    border: 1px solid #F8F9FA
    display: flex
    transition-duration: 0.4s
    &:hover
        background-color: #DEE2E6 !important
        color: variables.$black
        border: 1px solid #DEE2E6 !important
    &:active
        background-color: #DEE2E6 !important
        color: variables.$black
        border: 1px solid #DEE2E6 !important
    &:focus
        background-color: #DEE2E6 !important
        color: variables.$black
        border: 1px solid #DEE2E6 !important

    
    