//Font
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:wght@100;300;400;500;700&display=swap')
$open-sans: 'Open Sans', sans-serif
$open_sans: 'Open Sans'

.h1 
    font-family: $open_sans
    font-weight: 500
    font-size: 2.5rem
    line-height: 3.5rem

.h2 
    font-family: $open_sans
    font-weight: 500
    font-size: 2rem
    line-height: 2.75rem

.h3 
    font-family: $open_sans
    font-weight: 500
    font-size: 1.5rem
    line-height: 2.063rem

.p1 
    font-family: $open_sans
    font-weight: 500
    font-size: 1.25rem
    line-height: 1.75rem

.p2 
    font-family: $open_sans
    font-weight: 400
    font-size: 1.125rem
    line-height: 1.5rem

.body_medium 
    font-family: $open_sans
    font-weight: 500
    font-size: 1rem
    line-height: 1.375rem
 
.body_regular 
    font-family: $open_sans
    font-weight: 400
    font-size: 1rem
    line-height: 1.375rem

.body_light
    font-family: $open_sans
    font-weight: 300
    font-size: 1rem
    line-height: 1.375rem
 
.caption_medium
    font-family: $open_sans
    font-weight: 500
    font-size: 0.875rem
    line-height: 1.188rem

.caption-regular
    font-family: $open_sans
    font-weight: 400
    font-size: 0.875rem
    line-height: 1.188rem

.caption_light
    font-family: $open_sans
    font-weight: 300
    font-size: 0.875rem
    line-height: 1.188rem

.overline_medium
    font-family: $open_sans
    font-weight: 500
    font-size: 0.75rem
    line-height: 1rem
 
.overline_regular
    font-family: $open_sans
    font-weight: 400
    font-size: 0.75rem
    line-height: 1rem

.overline-light
    font-family: $open_sans
    font-weight: 300
    font-size: 0.75rem
    line-height: 1rem

.lable-medium
    font-family: $open_sans
    font-weight: 500
    font-size: 0.625rem
    line-height: 0.875rem

.lable-regular
    font-family: $open_sans
    font-weight: 400
    font-size: 0.625rem
    line-height: 0.875rem

.lable_light
    font-family: $open_sans
    font-weight: 300
    font-size: 0.625rem
    line-height: 0.875rem

//Color 
$primary: #16A085
$primary_dark: #3A7C69
$primary_light: #F0FCF9

$dark: #222
$coral: #4F5B62
$pewter:#819CA9
$gray: #CFD8DC
$disabled:#E8ECEF
$white: #FFF

$opacity: #FFFFFF00

// Other colors
$secondary: #175676
$secondary-dark:#0A2533
$secondary-light: #EEF7FC
$black: #201F1F
$coral: #4F5B62
$background: #FBFCFF
$cultured: #F3F2F1
$culture: #FAF9F8
$red: #AE1515
$red-light: #FFF2F3
$green: #397A26
$green-light: #E3F5DF

.caption-medium-outputForm
    font-family: $open_sans
    font-weight: 500
    font-size: 0.875rem
    line-height: 1.188rem
    color: $pewter
    margin-bottom: 6px
    margin-top: 12px

//Spacing
$spacing_md: 16px
$spacing_lg: 32px


//Border radius
$border_radius_sm:8px
$border_radius_md:12px

//Header height
$header_height:100px


//Breakpoints
$breakpoint-tablet: 960px
$breakpoint-phone: 576px

$sm: 576px
$md: 768px
$lg: 992px
$xl: 1280px
$xxl: 1920px

            
    
