@use '../../styles/variables.sass'

#containerNotSigned
    display: flex
    flex-direction: column
    align-items: center
     

    .logotipo
        width: 240px
        padding: 50px 0

    .p2
        padding: 24px 0
        color: variables.$coral
        text-align: center

    .ilustration
        width: 30%
        margin: 48px 0 

      
@media only screen and (max-width: variables.$lg)
    #container 
        .ilustration
            width: 90%
            margin: 100px 0 

    #containerNotSigned
        .logotipo
            width: 120px
            padding: 20px 0
    

        
       
