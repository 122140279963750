@use '../../../../styles/variables.sass'


.loading
    margin-left: 10px
    
.btn_primary
    background-color: variables.$primary
    border: 1px solid variables.$primary
    display: flex
    transition-duration: 0.4s 
    &:hover
        background-color: variables.$primary_dark
        border: 1px solid variables.$primary_dark
    &:active
        background-color: variables.$primary_dark !important
        border: 1px solid variables.$primary_dark !important
    &:disabled
        background-color: variables.$disabled !important
        color: variables.$white !important
        border: 1px solid variables.$disabled !important
